import { AppText, Column, Box, styled } from '@streem/ui-react';
import { FC, useEffect } from 'react';
import { ErrorBox } from '../components/error_box';
import appLogger from '../util/app_logger';

export function IOSUpdateRequiredPage() {
    useEffect(() => {
        appLogger.info('User arrived at iOS Update Required Page');
    }, []);

    return (
        <ErrorBox
            testid="ios-update-required"
            headerText="iOS Update Required"
            messageText={<IOSUpdateRequiredMessage />}
        />
    );
}

const IOSUpdateRequiredMessage = () => {
    return (
        <Column>
            <AppText bold style={{ fontSize: '18px' }}>
                Apple has identified an issue in this verion of iOS that has been resolved in iOS
                15.1. Update to the latest version of iOS.
            </AppText>
            <List>
                <ListItem>
                    Go to <BlueText bold>Settings &gt; General &gt; Software Update </BlueText> on
                    your phone and update to iOS 15.1 or later.
                </ListItem>
                <ListItem>After you've updated, click your invitation to connect.</ListItem>
            </List>
        </Column>
    );
};

const List = styled.ul`
    list-style: disc;
    padding-left: 25px;
    margin-bottom: ${props => props.theme.space[4]}px;
`;

const ListItem: FC = props => (
    <Box mt={4}>
        <AppText as="li" style={{ fontSize: '18px' }}>
            {props.children}
        </AppText>
    </Box>
);

const BlueText = styled(AppText)`
    font-size: 18px;
    color: ${props => props.theme.colors.azure};
`;
